body {
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
}

h1{
  color: rgb(255, 255, 255);
}

h4{
  color: rgba(187, 187, 187, 0.613);
}