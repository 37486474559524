.camera-serveur {
    width: 80%;
    height: 0;
    padding-top: 80%; 
    margin: 0 auto; 
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center; 
  }
  
  .camera-serveur video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .button-qrcode{
    color: rgba(187, 187, 187, 0.613);
    font-weight: bold;
  }